<template>
  <div class="content">
    <div class="title">
      <i class="el-icon-back" @click="$router.go(-1)"></i>
    </div>
    <div class="img">
      <img :src="imgUrl" alt="" />
    </div>
    <div class="information">
      <div class="project-name">项目：{{ project.name }}</div>
      <div class="user-name">画手：{{ project.painter_user_id }}</div>
    </div>
    <div class="images">
      <img
        :src="item"
        :class="checkIndex == index ? 'check' : ''"
        alt=""
        v-for="(item, index) in project.images"
        :key="index"
        @click="
          imgUrl = item;
          checkIndex = index;
        "
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checkIndex: 0,
      imgUrl: "",
      project: {},
    };
  },
  mounted() {
    if (!Object.keys(this.$route.params).length) {
      this.$router.go(-1);
      return;
    }
    this.project = this.$route.params;
    this.imgUrl = this.project.images[0];
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.content {
  .title {
    text-align: left;
    font-size: 25px;
    padding: 30px 25px;
    color: #ffffff;
  }
  .img {
    padding: 0 25px;
    background: #ffffff;
    img {
      width: 100%;
      display: block;
    }
  }
  .information {
    text-align: left;
    font-size: 13px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    line-height: 26px;
    padding: 0 25px;
    margin: 25px 0;
  }
  .images {
    display: flex;
    overflow: auto;
    .check {
      border: 2px solid #ebcd23;
    }
    img {
      margin: 0 5px;
      width: 124px;
      &:last-child {
        margin-right: 0;
      }
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
</style>